@use "assets/styles/global" as *;

.precheckout-product {
  align-items: center;
  background-color: white;
  display: flex;
  gap: 32px;
  padding: 24px 16px;

  .precheckout-product-name {
    font-weight: bold;
    margin: 0;
  }

  .counter {
    width: 100px;

    .counter-text-number {
      font-size: 14px;
    }
  }
}
