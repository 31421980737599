@use "assets/styles/global" as *;

.date-and-time-pickers {
  column-gap: 0.75rem;
  display: grid;
  grid-template-columns: 1fr;

  @include sm {
    grid-template-columns: 1fr 1fr;
  }

  .datepicker-section {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2px;
  }

  .timepicker-section {
    width: 100%;
  }
}

/* Placeholder styling inputs */
.datepicker-section .react-datepicker-wrapper input,
.timepicker-section .timepicker .timepicker-trigger {
  ::placeholder {
    font-weight: 900;
    text-transform: none;
    font-size: 16px;
    color: $text-color;
    font-family: $primary-font;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-weight: 900;
    text-transform: none;
    font-size: 16px;
    color: $text-color;
    font-family: $primary-font;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    font-weight: 900;
    text-transform: none;
    font-size: 16px;
    color: $text-color;
    font-family: $primary-font;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    font-weight: 900;
    text-transform: none;
    font-size: 16px;
    color: $text-color;
    font-family: $primary-font;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    font-weight: 900;
    text-transform: none;
    font-size: 16px;
    color: $text-color;
    font-family: $primary-font;

    opacity: 1;
  }
}
.react-datepicker {
  background-color: $datepicker-background-color;
  font-family: $primary-font;
  font-size: 14px;

  .react-datepicker__day--disabled {
    color: $datepicker-disabled-text-color;
  }

  .react-datepicker__day:not(.react-datepicker__day--disabled) {
    color: $datepicker-text-color;
  }
}
