@use "assets/styles/global" as *;

.category-page {
  .header-container .subheader,
  .page-view-content .page-title-container {
    display: none;

    @include sm {
      display: flex;
    }
  }

  .header-container.expanded-header-container .subheader {
    display: inherit;
  }

  .page-view-content.inner {
    padding: 0;

    @include sm {
      padding: 0 1rem;
    }
  }
}
