@use "assets/styles/global" as *;

.payment-section {
  .restaurant-info {
    display: flex;
    justify-content: center;
    margin: 2rem 0 1rem 0;

    .location-info-text {
      span {
        display: inline-block;
      }

      span:not(:last-child) {
        &::after {
          content: ",";
          margin-right: 3px;
        }
      }
    }
  }

  .finalize-buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 2rem;
    
    .button {
      font-size: 16px;
      width: 100%;

      @include md {
        margin: 0 0 0 1rem;
        width: auto;
      }
    }
  }
}
