@use "assets/styles/global" as *;

.terms-box {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem 0 0 0;
  user-select: none;
  cursor: pointer;

  @include md {
    justify-content: flex-end;
    padding: 1rem 1rem 0 0;
  }

  .checkbox-container {
    padding: 0.75rem;
    margin: 0 1rem 0 0;
    border: 1px solid $border-color;
    border-radius: 6px;

    @include md {
      margin: 0 1rem;
    }

    input[type="checkbox"] {
      width: 18px;
      height: 18px;
      margin-left: 2px;
      position: relative;
      cursor: pointer;
    }

    input[type="checkbox"]:before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      width: 100%;
      height: 100%;
      border: 1px solid $text-color;
      border-radius: 3px;
      background-color: white;
    }

    input[type="checkbox"]:checked:after {
      content: "";
      display: block;
      width: 5px;
      height: 10px;
      border: solid black;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 2px;
      left: 6px;
    }

    label {
      font-size: 14px;
    }
  }
}

.terms-checkbox .full.checkbox-label {
  .checkbox-label {
    cursor: pointer;
    display: inline;
  }
}
