@use "assets/styles/global" as *;

.basket-total {
  background-color: $basket-total-background-color;
  box-sizing: border-box;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  justify-content: space-between;
  padding: 20px;
  padding-right: calc(20px + 0.75rem + 28px);
  padding-bottom: 2em;
  text-transform: uppercase;
  width: 100%;

  @include sm {
    background-color: $basket-total-background-color-dark;
    font-size: 1rem;
    padding: 15px 30px;
  }
}
