@use "assets/styles/global" as *;

.header-container {
  background-color: $header-background-color;
  flex-direction: column;
  display: flex;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
  position: relative;
  z-index: 10; // Making sure it's always above the rest of the site
  width: 100%;

  @include sm {
    z-index: 11;
  }

  &.expanded-header-container {
    height: 100vh;
    height: -webkit-fill-available;

    display: flex;
    flex-direction: column;

    @include sm {
      height: auto;
      overflow: auto;
    }
  }

  .sidebar-container {
    @include sm {
      display: none;
    }
  }
}

.fasttrack .header-container {
  overflow: visible;
}
