@use "assets/styles/global" as *;

.header {
  background-color: $primary-color;
  height: $topbar-height;
  position: relative;
  width: 100%;
  width: -webkit-fill-available;

  .title {
    display: none;
    font-family: $accent-font-italic;
    font-size: 18px;
    color: white;
    position: absolute;
    text-decoration: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include sm {
      display: block;
    }
  }

  .smiley {
    display: none;
  }

  @include sm {
    height: $topbar-height-sm;

    .minicart {
      display: none;
    }

    .smiley {
      display: block;
    }
  }

  .inner {
    align-items: center;
    background-color: inherit;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    position: relative;
  }
}

.header-section {
  flex-grow: 1;
}

.minicart-section {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;

  & > * + * {
    margin-left: 1rem;
  }

  @include md {
    align-items: center;
  }
}

.mobile-sidebar-trigger {
  // The button in the mobile header to show the sidebar

  .mobile-sidebar-button {
    font-size: 28px;
    font-weight: normal;

    color: $sidebar-text-color;
    background: transparent;

    height: 50px;
    width: 50px;

    padding: 0;
    margin-bottom: 7px;

    border: none;
    user-select: none;
    outline: none;

    cursor: pointer;

    &:focus {
      outline: none;
    }

    @include sm {
      display: none;
    }
  }
}

.fasttrack .header {
  height: 60px;

  .inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
  }

  .logo-container {
    left: 50%;
    position: absolute;
    top: 20px;
    transform: translateX(-50%);

    @include sm {
      left: 0;
      transform: none;
      top: -10px;
    }

    .logo {
      height: 47px;
      width: 40px;
    }
  }

  .cart-section {
    display: flex;
    justify-content: flex-end;
  }

  .summarycart {
    display: none;

    @include sm {
      display: block;
    }
  }
}
