@use "assets/styles/global" as *;
@import "assets/styles/base";

.checkout-content {
  .orderlist-item {
    grid-template-columns: 2fr 8fr 3fr;
    column-gap: 0;
    row-gap: 0.25rem;

    &.with-choices {
      row-gap: 0.5rem;
    }

    @include sm {
      grid-template-columns: 1fr 6fr 4fr 2fr;
      column-gap: 0.75rem;

      & > :nth-child(1) {
        order: 1;
      }

      & > :nth-child(2) {
        order: 2;
      }

      & > :nth-child(4) {
        order: 3;
      }

      & > :nth-child(3) {
        order: 4;
      }
    }

    .orderlist-item-delete {
      grid-column: span 4;
      justify-self: end;

      @include sm {
        grid-column: span 1;
        order: 2;
        justify-self: center;
      }

      .button-muted {
        @extend .button, .button-colored, .gray;
        
        font-family: $primary-font;
        font-size: 14px;
        padding: 8px 16px;

        @include sm {
          font-size: 16px;
        }
      }
    }
  }
}

.footer {
  display: none;
  justify-content: flex-end;

  @include sm {
    display: flex;
  }

  button {
    margin-top: 2rem;
  }
}
