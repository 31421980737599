@use "assets/styles/global" as *;

.orderlist {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.empty-orderlist {
  text-align: center;
}
