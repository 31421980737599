@use "assets/styles/global" as *;

.delivery-info-subtitle {
  font-weight: bold;
}

.opening-hours {
  .opening-hours-hour-interval {
    font-weight: bold;
  }
}

.delivery-info-input-section {
  @include sm {
    margin-bottom: 1rem;
  }

  .input-form {
    display: grid;
    grid-template-columns: 1fr;
    
    @include sm {
      column-gap: 0.75rem;
      grid-template-columns: 1fr 1fr;
    }

    input {
      font-size: 16px;
    }
  }
}
