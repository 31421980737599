@use "assets/styles/global" as *;

.precheckout {
  background-color: $expanded-cart-background-color;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
  left: 0;
  position: relative;
  right: 0;
  top: 0;

  @include sm {
    background: none;
    position: relative;
  }

  &.content {
    @include sm {
      padding: 0;
      margin: 0;
    }
  }

  .back-button {
    background: none;
    cursor: pointer;
    border: none;
    display: flex;

    span.icon {
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: contain;
      border: none;
      cursor: pointer;
      max-height: 100%;
      overflow: hidden;
      padding: 0.5rem;
      transform: rotate(-90deg);
    }

    @include sm {
      margin-bottom: 16px;
    }
  }

  .precheckout-title {
    font-family: $accent-font-italic;
    font-size: 32px;
    font-weight: normal;
    margin: 0;
    margin-top: 32px;
    text-align: center;
  }

  .product-list {
    column-gap: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    row-gap: 0;

    .precheckout-product {
      border-bottom: 2px dashed $expanded-cart-background-color;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  // Mobile only
  .continue-button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 4px 16px;

    @include sm {
      display: none;
    }

    .item-count {
      align-items: center;
      background-color: $primary-color-dark;
      display: flex;
      justify-content: center;
      height: 24px;
      width: 24px;
    }

    &:hover .item-count {
      color: white;
    }
  }
}
