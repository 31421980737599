@use "assets/styles/global" as *;

.minicart {
  background-color: transparent;
  font-family: $accent-font-italic;
  background-size: 0px;
  padding: 0;
  margin-left: 16px;

  min-width: 2rem;
  min-height: 100%;
  height: 20px;

  border: none;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  position: relative;

  @include md {
    min-width: 5rem;
    min-height: 44px;
    margin-left: 1.2rem;
  }

  &:before {
    content: "";
    position: absolute;
    height: 20px;
    width: 26px;
    left: auto;
    bottom: 0;

    background-image: inherit;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @include md {
      bottom: 17px;
      left: auto;

      background-image: inherit;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  &.click-allowed {
    &:before {
      left: auto;
    }

    @include md {
      &:before {
        left: auto;
      }
    }
  }

  .ordernumber {
    position: absolute;
    text-align: center;
    font-family: $secondary-font;
    font-size: 10px;
    font-weight: bold;
    text-indent: 0;

    width: 16px;
    height: 16px;
    line-height: 16px;

    -moz-border-radius: 50%;
    border-radius: 50%;

    background-color: $minicart-bubble-color;
    color: $minicart-bubble-text-color;

    top: -4px;
    margin-left: 20px;

    @include md {
      width: 18px;
      height: 18px;
      line-height: 18px;
      top: -10px;
      margin-left: 20px;
    }
  }

  &:focus {
    outline: none;
  }
}
