@use "assets/styles/global" as *;

.sidebar-container {
  animation-duration: 500ms;
  animation-name: mobile-navigation-fadein;
  margin-top: 1rem;

  &.closing {
    animation-name: mobile-navigation-fadeout;
    max-height: 0;
  }
}

.sidebar {
  display: none; // Hiding on mobile, unless in the right container

  background-color: $sidebar-background-color;

  margin: 0;
  padding: 0;

  flex-direction: column;
  justify-content: flex-start;

  height: 100%;
  overflow-y: auto;

  @include sm {
    display: flex; // Always showing on desktop
    position: relative;

    margin-bottom: 1rem;
    padding: 0;
  }

  .smiley {
    align-self: flex-end;
    margin: 1rem;

    @include sm {
      display: none;
    }
  }

  .sidebar-item {
    border-bottom: 1px solid $sidebar-border-color;
    color: $sidebar-text-color;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 1rem;
    text-transform: uppercase;

    cursor: pointer;

    &.active {
      background-color: $sidebar-active-background-color;
    }

    &:hover {
      background-color: $sidebar-accent-background-color;
      color: $sidebar-accent-text-color;
    }

    &:nth-last-child(1) {
      border-bottom: none;
    }

    @include sm {
      border: none;
    }

    @include md {
      font-size: 16px;
    }
  }

  .active {
    background-color: $sidebar-accent-background-color;
    color: $sidebar-accent-text-color;

    @include md {
      background-color: $sidebar-accent-background-color;
      color: $sidebar-accent-text-color;
    }
  }
}

.event .sidebar-container {
  width: 100%;

  .sidebar {
    background-color: $event-blue;
    display: block;

    .smiley {
      display: none;
    }

    .sidebar-item {
      border: none;
      text-transform: capitalize;

      span.name {
        position: relative;
      }

      &:hover {
        background: none;
        color: $event-beige;
      }
    }

    .sidebar-item.active {
      background-color: initial;
      color: $event-beige;
      cursor: default;

      span.name::before {
        border-bottom: 1px solid $event-beige;
        top: calc(100% + 5px);
        content: "";
        position: absolute;
        width: 40px;
      }
    }
  }
}

// Sidebar på mobil
.header-container {
  .sidebar-container {
    overflow: auto;
    width: 100%;
    margin: 0;
    padding: 0;
    flex-grow: 1;
    max-height: 100vh;
    max-height: -webkit-fill-available;
    background-color: $sidebar-background-color;
    transform: translate3d(0, 0, 0);

    .sidebar {
      display: flex;

      .active .dot {
        height: 8px;
        width: 8px;
        margin-right: 1rem;
        background-color: $sidebar-accent-text-color;
        border-radius: 50%;
        display: inline-block;
      }
    }
  }
}
