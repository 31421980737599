@keyframes fadein {
  0% {
    opacity: 0;
    height: 0;
    visibility: hidden;
  }

  50% {
    opacity: 0;
    height: auto;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    height: auto;
    max-height: 100%;
    visibility: visible;
  }
}

@keyframes mobile-navigation-fadein {
  from {
    max-height: 0;
  }

  to {
    max-height: 100vh;
  }
}

@keyframes mobile-navigation-fadeout {
  from {
    max-height: 50vh;
  }

  to {
    max-height: 0;
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
