@use "assets/styles/global" as *;

.timepicker {
  height: auto;
  width: 100%;

  .timepicker-trigger {
    width: calc(100% - 2.8em);
    padding: 1.25em 1.4em;
    font-size: 16px;
    height: 1em;
    font-weight: bold;

    border: none;
    background-color: $input-color;

    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .timepicker-root {
    background-color: $timepicker-background-color;
    padding: 0.5rem;
    border-bottom: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-left: 1px solid $border-color;

    @include md {
      padding: 1rem;
    }

    .picker-container {
      touch-action: none;
    }

    .timepicker-empty {
      color: $timepicker-text-color;
    }

    .timepicker-header {
      text-align: center;
      padding: 1rem 0;
      color: $timepicker-text-color;
    }

    ul.timepicker-body {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      overflow: hidden;

      li {
        padding: 0.5rem 0;
        font-size: 13px;
        color: $timepicker-text-color;
        text-align: center;
        cursor: pointer;

        @include md {
          min-width: 2rem;
          font-size: 16px;
        }
      }
    }
  }
}

.fasttrack .timepicker .timepicker-root .timepicker-body {
  ul.timepicker-row li {
    opacity: 1;
  }
}

.event .timepicker .timepicker-trigger {
  font-family: $event-primary-font;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: $event-primary-font;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    font-family: $event-primary-font;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    font-family: $event-primary-font;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    font-family: $event-primary-font;
  }
}
