@use "assets/styles/global" as *;

/* Discount and giftcard input fields */

.cart-input-container {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.input-form.cart-input-form {
  flex-grow: 1;

  .input-item {
    width: 100%;
    margin: 0;
    position: relative;

    input {
      height: 48px;
      padding: 0 1.3rem;
      font-size: 16px;

      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      background-color: $input-color;
    }
  }

  .input-spinner {
    height: 16px;
    width: 16px;
    top: 12px;
    left: unset;
    right: 24px;
  }

  .input-result {
    position: absolute;
    right: 24px;
    top: 14px;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }

  .input-error {
    display: block;
    width: 100%;
    text-align: right;

    margin: 0;
    padding: 0;
    border: 0;

    font-size: 13px;
  }

  .input-note {
    font-size: 14px;
    width: 100%;
    text-align: right;
    margin: 0;
  }
}

.cart-input-button {
  height: 48px;
  font-size: 14px;
  padding: 16px 1.1rem;
  border: none;

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
