@use "assets/styles/global" as *;

.subheader {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 80px;

  @include sm {
    height: 100px;
  }
  
  .inner {
    background-color: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

    h1 {
      background-color: white;
      color: $primary-color;
      font-size: 1rem;
      line-height: 46px;
      text-transform: uppercase;
      padding: 0 1rem;
      width: 100%;
      
      @include sm {
        min-width: 40%;
        width: auto;
      }
      
      @include md {
        font-size: 22px;
        min-width: 50%;
      }
    }
    
    .summarycart {
      display: none;
      
      @include sm {
        display: flex;
        margin-left: 1rem;
      }
    }    
  }
}
