/* Icons */

.collapsible-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("https://billedarkiv.blob.core.windows.net/images/bbad43d7-a093-4fec-af1b-b8053bbc442d");
  background-size: cover;
  background-repeat: no-repeat;
}

.exit-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("https://billedarkiv.blob.core.windows.net/images/e56ee19f-504d-4598-9757-7eb578625dc0");
  background-size: cover;
  background-repeat: no-repeat;
}

.count-btn {
  &.minus {
    background-image: url("https://billedarkiv.blob.core.windows.net/images/3fa0f7bf-e420-481d-9abd-ab8f4a2deaf8");
  }

  &.plus {
    background-image: url("https://billedarkiv.blob.core.windows.net/images/21301847-18f5-40b1-bf08-a14efbfe7e7c");
  }
}
