@use "assets/styles/global" as *;

.language-picker {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 20px;
  width: 20px;
}
