@use "assets/styles/global" as *;

.product-modifier-container {
  animation-duration: 500ms;
  animation-name: fadein;
  background-color: $product-modifier-background-color;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 45px 35px;

  @include sm {
    padding: 80px;
    padding-top: 50px;
  }

  .modifier {
    align-items: center;
    border: 1px dashed $modifier-border-color;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .modifier-title {
    background: $product-modifier-background-color;
    color: $modifier-title-text-color;
    font-family: $accent-font-italic;
    font-weight: normal;
    font-size: 24px;
    padding: 0 6px;
    position: absolute;
    top: -17px;

    @include sm {
      font-size: 26px;
    }

    .modifier-counter {
      color: $modifier-counter-text-color;
      font-family: $accent-font;
      font-size: 26px;
      line-height: 1.05;
      margin-right: 0.5rem;

      @include sm {
        font-size: 42px;
      }
    }

    .collapsible-icon {
      position: absolute;
      right: 0;
    }
  }

  .single-missing-choices-warning {
    display: block;

    text-align: center;
    font-size: 0.8em;
    margin: 0.5rem 0 0 0;
    font-weight: bold;

    position: relative;
    width: 100%;

    color: $error-color;

    @include md {
      margin: 0;
      position: absolute;
    }
  }

  .collapsed-text {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .collapsed-chosen-options,
    .collapsed-price {
      margin: 0.5rem 0;
      font-size: 14px;
      font-style: italic;

      @include md {
        font-size: 15px;
      }
    }

    .collapsed-chosen-options {
      max-width: 80%;
    }

    .collapsed-price {
      right: 0;
    }
  }
}

.product-modifier-footer {
  .missing-choices-warning {
    position: relative;

    font-family: $secondary-font;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;

    color: $error-color;

    @include sm {
      font-size: 14px;
    }

    ul {
      margin: 0;
      list-style: none;
      padding: 0;
      margin-bottom: 16px;
    }
  }

  .dismiss-container {
    border-top: 1px solid $expanded-cart-border-color;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding-top: 1rem;
    width: 100%;

    .dismiss-button {
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: contain;
      border: none;
      cursor: pointer;
      height: 14px;
      padding: 0.5rem;
      width: 24px;
    }
  }

  .button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

// Handling collapsed/expanded modifier

.modifier {
  &.expanded {
    .single-missing-choices-warning,
    .collapsed-text {
      display: none;
    }
  }

  &.collapsed {
    .choice-list {
      margin: 0;

      .choice {
        display: none;
      }
    }
  }

  footer {
    align-items: center;
    display: flex;
    font-weight: 700;
    justify-content: space-between;

    p {
      margin: 0;
    }

    .extra-choice-text {
      font-size: 12px;
      color: $modifier-extra-choice-text-color;

      @include sm {
        font-size: 14px;
      }
    }

    .extra-choice-price {
      font-size: 16px;

      @include sm {
        font-size: 18px;
      }
    }
  }
}

.quantity-modifier .modifier-content {
  padding: 30px 0 22px 0;

  .counter {
    border: 1px solid $counter-border-color;
    border-radius: 4px;
    box-shadow: inset 0px 0px 6px #00000029;
    padding: 3px;
  }
}

/* Fasttrack */
.fasttrack .product-modifier-container {
  .modifier {
    padding-bottom: 0.25rem;
  }

  .modifier-title {
    font-family: $primary-font;
    font-size: 12px;
    font-weight: 700;

    @include sm {
      font-size: 14px;
    }
  }

  .modifier-counter {
    display: none;
  }

  .choice-list {
    margin: 0.25rem 0;

    @include sm {
      column-gap: 0.5rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @include md {
      max-width: none;
    }

    .choice {
      padding-top: 0.125rem;
      padding-bottom: 0.125rem;

      @include sm {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }

    .choice-name {
      font-size: 9px;
      width: 100%;

      @include sm {
        font-size: 13.5px;
      }
    }
  }

  .extra-choice-text {
    @include sm {
      font-family: $secondary-font;
      color: black;
    }
  }

  .checkout-button {
    display: none;
  }

  .simple-total {
    font-size: 16px;
  }
}
