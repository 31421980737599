@use "assets/styles/global" as *;

.delivery-picker {
  column-gap: 0.75rem;
  display: grid;
  grid-template-columns: 1fr;
  font-size: 14px;
  margin-bottom: 0.5rem;
  
  @include sm {
    grid-template-columns: 1fr 1fr;
    font-size: 16px;
    margin-bottom: 1.5rem;
  }
}

.delivery-picker-option {
  align-items: baseline;
  background-color: $delivery-option-background-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  font-weight: bold;
  justify-content: flex-start;
  padding: 1rem;

  &:first-child {
    margin-bottom: 0.5rem;
  }

  &.active {
    background-color: $primary-color;
    font-weight: normal;
  }

  @include sm {
    &:first-child {
      margin-bottom: 0;
    }
  }

  span {
    margin-left: 0.5rem;
  }

  .delivery-disabled-text {
    opacity: 0.5;
    font-size: 9px;
    font-style: italic;
    font-weight: normal;
    margin-left: 0.5rem;
    text-align: left;

    @include md {
      font-size: 11px;
    }
  }
}
