@use "assets/styles/global" as *;

.logo-container {
  align-items: center;
  justify-content: center;
  display: flex;
}

.logo {
  align-self: center;
  height: 52px;

  @include sm {
    position: absolute;
    height: auto;
    left: 1rem;
    top: 1rem;
    width: 140px;
  }
}
