@use "assets/styles/global" as *;

$summarycart-padding: 0.5rem;

.summarycart {
  background-color: $summarycart-background-color;
  border: none;
  display: flex;
  color: $summarycart-text-color;
  cursor: pointer;
  flex-direction: row;
  font-family: $primary-font;
  font-size: 12px;
  justify-content: flex-end;
  padding: $summarycart-padding;
  position: relative;
  transition: color 0.25s, background-color 0.25s;
  text-transform: uppercase;
  width: 200px;

  .ordernumber {
    align-items: center;
    background-color: $summarycart-bubble-color;
    border-radius: 50%;
    color: $summarycart-bubble-text-color;
    display: flex;
    font-size: 13px;
    font-weight: 700;
    height: 26px;
    justify-content: center;
    left: 18px;
    position: absolute;
    top: -4px;
    width: 26px;
    z-index: 1;
  }

  img {
    left: $summarycart-padding;
    height: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .ordertotal {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    justify-content: flex-end;

    .total {
      font-weight: bold;
    }
  }
}

.event .summarycart {
  background: none;
  border: 1px solid white;

  img {
    display: none;
  }

  .ordernumber {
    background-color: $event-blue;
    left: 6px;
  }

  .ordertotal {
    color: $event-blue;
    text-transform: none;

    &,
    .total {
      font-weight: normal;
    }
  }
}
