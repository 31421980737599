@use "assets/styles/global" as *;

.expanded-cart {
  background-color: $expanded-cart-background-color;
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 24px;

  transform: translate3d(0, 0, 0); // iOS Safari hack

  width: 100%;

  @include sm {
    animation: none;
    bottom: 0;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    max-height: -webkit-fill-available;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: $topbar-height-sm;
    width: 400px;
    z-index: 11;
  }

  @include md {
    width: 500px;
  }

  .basket-total .total-amount {
    text-align: right;
  }

  &.closing {
    animation: none;
  }

  & > header {
    padding: 16px;
    text-align: center;

    @include sm {
      border-bottom: 2px dashed #d4d1c2;
      display: flex;
      justify-content: flex-end;
      padding: 0;
      padding-bottom: 40px;
      margin: 2rem;
      margin-bottom: 0;
    }

    h1 {
      font-family: $accent-font-italic;
      font-size: 2rem;
      font-weight: normal;
      margin: 0;

      @include sm {
        font-size: 36px;
        flex-grow: 1;
        text-align: center;
      }
    }

    button {
      align-items: center;
      background-color: #ccc8b4;
      border: none;
      border-radius: 100%;
      cursor: pointer;
      display: none;
      height: 35px;
      justify-content: center;
      width: 35px;

      @include sm {
        display: flex;
      }

      i {
        display: block;
        height: 25px;
        width: 25px;
      }
    }
  }

  &.show-precheckout {
    & > header {
      display: none;
    }

    .orderlist-container {
      display: none;
    }

    .precheckout-container {
      display: block;

      @include sm {
        margin-top: 0;
      }
    }
  }

  .precheckout-container {
    display: none;

    .precheckout {
      margin-top: 0;

      &.content {
        @include sm {
          padding: 0;
        }
      }

      .precheckout-title {
        @include sm {
          margin-top: 0;
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    padding: 2rem;

    @include sm {
      overflow-y: auto;
      flex-grow: 1;
    }

    .orderlist-container > * + * {
      margin-top: 1rem;
    }

    .cart-cta-button {
      button {
        font-size: 1rem;
        padding: 14px 18px;
        width: 100%;
      }

      @include sm {
        display: none;
      }
    }
  }

  .footer {
    border-top: 1px solid $expanded-cart-border-color;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    overflow: hidden;
    padding-top: 1rem;
    width: 100%;

    @include sm {
      border: none;
      box-sizing: border-box;
      padding: 20px 2rem;
      flex-shrink: 0;
    }

    // Desktop only
    .continue-button {
      align-items: center;
      display: none;
      justify-content: space-between;
      margin: 0;
      padding: 0 36px;
      width: 100%;

      @include sm {
        display: flex;
      }

      .item-count {
        align-items: center;
        background-color: $secondary-color-dark;
        display: flex;
        justify-content: center;
        height: 24px;
        width: 24px;
      }

      &:hover .item-count {
        color: white;
      }
    }

    .close-button {
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: contain;
      border: none;
      cursor: pointer;
      height: 14px;
      padding: 0.5rem;
      width: 24px;

      @include sm {
        display: none;
      }
    }
  }
}
