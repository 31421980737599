@use "assets/styles/global" as *;

.content-section {
  text-align: left;
  padding: 1rem 0;
  margin-bottom: 2rem;

  &.collapsible-section.content-title-container {
    cursor: pointer;
  }

  .content-title-container {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
  }

  .content-title {
    color: $checkout-title-text-color;
    font-family: $accent-font-italic;
    font-size: 22px;
    font-weight: normal;

    @include sm {
      font-size: 26px;
    }

    .number {
      color: $checkout-title-count-text-color;
      font-family: $accent-font;
      font-size: 36px;
      font-weight: 700;
      margin-right: 3px;
    }
  }

  .content-body {
    margin-top: 1rem;
  }
}

.event .content-section {
  .content-title {
    font-family: $event-secondary-font;
    color: $event-blue;

    .number {
      color: inherit;
    }
  }
}
