@use "assets/styles/global" as *;

.inline-restaurant-pickup-info {
  background-color: $checkout-inline-pickup-background-color;
  color: $checkout-inline-pickup-text-color;
  margin-bottom: 1rem;
  padding: 1rem;
  
  & > * {
    margin: 0;
    margin-top: 0.25rem;
  }

  @include sm {
    display: flex;

    & > * {
      margin-top: 0;
      margin-right: 0.5rem;
    }
  }
  
  h1 {
    font-size: 14px;
    font-weight: normal;

    @include sm {
      font-size: 16px;
    }
  }

  .location-name {
    color: $checkout-inline-pickup-text-color;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .location-phone {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.event .inline-restaurant-pickup-info {
  background-color: $event-blue;
}
