.page-view {
  background-color: $background-color;
  padding-bottom: 2rem;
}

.page-view.event {
  background-color: $event-beige;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 0;
}

.inner {
  color: $text-color;
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 1rem;
}

// Layout for any pages with no other content than a status - e.g. error, maintenance, order, confirmation

.status-page {
  .status-page-text {
    width: 100%;
    text-align: center;
  }

  .button {
    display: block;
    margin: 1rem auto;
  }
}

.root-page .page-view-content.inner {
  padding: 0;
}

.content-body {
  width: 100%;

  &.sidebar-body {
    // Content with the sidebar on the left
    display: flex;
    flex-direction: column-reverse;
    width: 100%;

    @include sm {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }

    & > div:nth-child(1) {
      width: 0;

      @include sm {
        width: 22%;
        margin-right: 1rem;
      }
    }

    & > div:nth-child(2) {
      width: 100%;
      flex-grow: 1;

      @include sm {
        width: 75%;
      }
    }
  }
}

.event .content-body.sidebar-body {
  flex-direction: column;

  @include sm {
    flex-direction: row;
  }

  & > div:nth-child(1) {
    width: auto;
  }
}

// Accordion style with rotating icon
.collapsible-section {
  &.expanded {
    .collapsible-icon {
      animation-duration: 500ms;
      animation-name: rotate;
      transform: rotate(180deg);
    }

    .collapsible-content {
      opacity: 1;
      transition: opacity 500ms ease;
    }
  }

  &.collapsed {
    .collapsible-content {
      opacity: 0;
      visibility: hidden;
      height: 0;
      margin: 0;
    }
  }
}

#page-overlay,
#header-overlay {
  @include sm {
    background: rgba(black, 0.5);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
}
