@use "assets/styles/global" as *;

.choice-list {
  align-items: flex-start;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin-top: 1rem;
  padding: 25px;
  width: 100%;

  @include md {
    grid-template-columns: 1fr 1fr;
  }
}

.choice {
  background-color: $choice-background-color;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-size: 16px;
  user-select: none;

  .choice-image {
    aspect-ratio: 1/1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px 8px 0 0;
    width: 100%;
  }

  .choice-input {
    display: flex;
    gap: 16px;
    min-height: 56px;
    padding: 24px;
  }

  .choice-details {
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    width: 100%;

    .choice-name {
      flex-grow: 1;
    }

    .choice-info {
      display: inline-flex;
      position: relative;

      .choice-info-icon {
        border-radius: 50%;
        background-color: $choice-info-background-color;
        color: $choice-info-text-color;
        width: 18px;
        height: 18px;
        display: inline-flex;
        margin-left: 4px;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
        z-index: 1;
      }

      .choice-info-box {
        display: none;
        background: black;
        font-size: 12px;
        color: white;
        margin: 0;
        padding: 0.5rem 1rem;
        position: absolute;
        top: 18px;
        left: 18px;
        width: 10rem;
        z-index: 2;
      }

      .choice-info-icon:hover + .choice-info-box {
        display: block;
      }
    }

    .choice-price {
      color: #303030;
      flex-shrink: 0;
      opacity: 0.75;
      text-align: right;
      white-space: nowrap;
    }
  }

  &.active {
    .choice-name,
    .counter-text-number {
      color: $choice-active-text-color;
    }
  }

  .choice-name,
  .choice-price {
    font-weight: bold;
    margin: 0;
  }

  .choice-name-container {
    flex-grow: 1;
  }

  .choice-price {
    width: 15%;
  }
}

.counter {
  @include sm {
    font-size: 18px;
  }

  .counter-text-extra {
    display: none;
  }
}

.choice-list-collapse {
  display: flex;
  justify-content: center;

  .collapsible-icon {
    margin-top: 0.5rem;
    width: 2rem;
    height: 2rem;
  }
}
