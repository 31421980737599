@use "assets/styles/global" as *;

.itemized-total-section {
  .total-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0.5rem 0;
    border-top: 1px solid $border-color;

    .total-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 14px;

      @include md {
        width: 25%;
        padding: 2px 0;
      }

      .total-item-left,
      .total-item-right {
        margin: 0;
      }
    }
  }
}
