@use "assets/styles/global" as *;

$product-image-height: 260px;

.product-list {
  display: grid;
  column-gap: 0.5rem;
  margin-top: 1rem;
  row-gap: 0.5rem;

  @include sm {
    column-gap: 1rem;
    row-gap: 1rem;
  }
}

.product {
  animation-duration: 500ms;
  animation-name: fadein;
  background-color: $product-background-color;
  overflow: hidden;
  position: relative;
  
  @include sm {
    width: 840px;
  }

  &.blocked {
    display: none;
    pointer-events: none;
  }

  @include sm {
    background: none;
    margin-top: 1rem;
  }
}

.product-content {
  display: flex;
  flex-direction: column;
  margin-bottom: -15px;

  @include sm {
    background-color: $product-modifier-background-color;
    flex-direction: column-reverse;
    margin-bottom: 0;
  }

  .product-image {
    aspect-ratio: 1 / 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    flex-shrink: 0;
    height: $product-image-height;
    width: 100%;
    z-index: 1;

    @include sm {
      box-sizing: border-box;
      margin: 0 auto;
      width: calc(100% - 2 * 80px);
    }
  }

  .product-details {
    background-color: $product-background-color;
    border-radius: 22px 22px 0 0;
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-top: 25px;
    position: relative;
    top: -25px;
    z-index: 2;

    @include sm {
      border-radius: 0;
      padding: 32px 50px;
      top: 0;
    }

    &.active {
      .counter-text-number {
        color: $product-active-counter-number-text-color;
      }
    }

    @include sm {
      flex-direction: row;
      justify-content: space-between;
    }

    button {
      align-items: baseline;
      display: flex;

      @include sm {
        align-items: center;
        padding: 1rem 1rem;
      }

      @include md {
        white-space: nowrap;
      }

      &.active {
        background-color: $product-active-button-background-color;
        border-color: $product-active-button-border-color;

        span {
          transform: rotate(180deg);
        }
      }

      span {
        background-repeat: no-repeat;
        height: 12px;
        margin-left: 0.25rem;
        width: 12px;

        @include sm {
          height: 16px;
          margin-left: 0.5rem;
          width: 16px;
        }
      }
    }

    .product-price-sale {
      color: $product-sale-price-text-color;
      font-weight: bold;
      margin: 0;
      text-align: right;

      span {
        margin-left: 0.25rem;
      }
    }
  }

  .product-name-container {
    border-bottom: 1px dashed $product-border-color;
    padding: 20px;
    padding-top: 0;

    @include sm {
      border: none;
      padding: 0;
      width: 100%;
    }

    .product-name {
      font-family: $accent-font;
      font-size: 32px;
      font-style: italic;
      font-weight: 900;
      color: $product-title-color;
      text-align: center;
      word-break: break-word;

      @include sm {
        border-bottom: 2px dashed #b3b1a8;
        font-size: 30px;
        font-family: $primary-font;
        font-style: normal;
        padding-right: 150px;
        padding-bottom: 32px;
        text-align: left;
      }
    }

    .product-subtitle-container {
      align-items: center;
      display: flex;
    }

    .product-subtitle {
      color: $product-subtitle-color;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;

      @include sm {
        font-size: 14px;
      }
    }

    .labels {
      padding: 0;
      padding-left: 0.5rem;
    }

    .product-description {
      color: $product-text-color;
      font-family: $primary-font;
      font-size: 14px;
      line-height: 14px;
      margin: 20px 0 0 0;
      text-align: center;
      white-space: pre-line;

      @include sm {
        font-size: 16px;
        text-align: left;
      }
    }
  }

  .product-action-container {
    padding-top: 8px;

    @include sm {
      padding: 0;
      position: absolute;
      right: 50px;
      top: 32px;
    }
  }

  .product-price {
    color: $product-title-color;
    font-size: 32px;
    font-weight: 600;
    text-align: center;

    @include sm {
      font-size: 32px;
      text-align: right;
    }

    .product-price-text {
      color: inherit;
      font-size: 18px;
      margin-left: 0.3rem;

      @include sm {
        font-size: 20px;
      }
    }
  }

  .button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    @include sm {
      padding: 12px 13.5px 9px 13.5px;
      width: auto;
    }

    @include md {
      font-size: 16px;
      justify-content: center;
      padding: 0.4rem;
    }

    &:focus {
      outline: none;
    }

    .collapsible-icon {
      line-height: 0;
      margin-left: 5px;
    }
  }

  // .product-price,
  // .product-name-container .product-name {
  //   @include sm {
  //     border-bottom: 2px dashed #b3b1a8;
  //     height: 36px;
  //     padding-bottom: 24px;
  //   }
  // }
}
