@use "assets/styles/global" as *;

$col-gap: 24px;
$grid-template-columns: 10% 1fr 1fr 10%;

.orderlist-item {
  align-items: flex-start;
  background-color: white;
  column-gap: 1.5rem;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: space-between;
  position: relative;
  padding: 30px 20px;

  @include sm {
    font-size: 16px;
  }

  .product {
    column-gap: $col-gap;
    display: grid;
    grid-template-columns: $grid-template-columns;
    width: 100%;
  }

  &.with-choices .product {
    margin-bottom: 16px;
  }

  .product-name {
    text-transform: uppercase;
  }

  .product-price {
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;
  }

  .product-delete {
    background: none;
    border: none;
    color: #747165;
    cursor: pointer;
    font-family: $primary-font;
    font-size: 12px;
    height: 16px;
    padding: 0;
    text-decoration: underline;
    text-transform: uppercase;
  }
}

.product-modifiers {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  row-gap: 16px;
  width: 100%;

  .product-choices {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    row-gap: 4px;

    .product-choice {
      column-gap: $col-gap;
      display: grid;
      grid-template-columns: $grid-template-columns;

      .choice-name {
        grid-column: 2;
      }

      .choice-price {
        grid-column: 3;
        text-align: right;
      }
    }
  }
}

.orderlist-item-col {
  & > span {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.orderlist-item-choice-list {
  font-weight: normal;
  font-family: $secondary-font;
  list-style: none;
  margin: 0;
  padding: 0.25rem 0;
  font-size: 14px;

  li {
    line-height: 1.4;
  }

  &.choices-price li {
    text-align: right;
  }

  .empty-choice-price {
    height: 20px;
  }
}
