@use "assets/styles/global" as *;

.checkout-content {
  padding-bottom: 2rem;

  .content-section {
    margin-bottom: 1rem;
  }

  .content-section:not(:first-child),
  .delivery-method-section {
    border-top: 1px solid $checkout-border-color;
  }

  .orderlist {
    margin-bottom: 1rem;
    padding: 0;

    .orderlist-item {
      background-color: white;
      font-size: 14px;
      margin-top: 2px;
      padding: 14px 12px;

      @include sm {
        font-size: 16px;
      }
    }

    .item-name-number {
      display: flex;
      flex-grow: 1;
      max-width: 50%;
    }
  }

  .basket-total {
    background-color: $checkout-total-background-color;
  }

  .checkbox-container {
    .checkbox-label {
      align-items: center;

      @include md {
        justify-content: flex-end;
      }
    }
  }

  .input-form {
    display: grid;
    grid-template-columns: 1fr;

    @include sm {
      column-gap: 0.75rem;
      row-gap: 0.75rem;
      grid-template-columns: repeat(2, 1fr);

      .input-item {
        margin-right: 0;
      }

      .input-item:nth-child(3) {
        grid-column: span 2 / span 2;
      }
    }
  }

  .date-and-time-pickers .datepicker-section {
    margin-right: 1rem;
  }
}

.event.checkout-page .content-title-container {
  border-color: #00000040;
}

.event .checkout-content {
  .content-section:not(:first-child),
  .delivery-method-section {
    border-color: #00000040;
  }
  
  .orderlist .orderlist-item,
  .basket-total {
    background: none;
  }
}

.content-body.order {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 3rem;

  .spinner {
    bottom: auto;
    flex-shrink: 0;
    left: auto;
    position: relative;
    right: auto;
    top: auto;
  }
}
