@use "assets/styles/global" as *;

.checkbox-container {
  margin: 1rem 0;

  .checkbox-label {
    display: inline-flex;
    font-size: 14px;
    white-space: pre-wrap;
    cursor: pointer;

    @include sm {
      font-size: 16px;
    }
  }

  .checkbox {
    border-radius: 0;
    margin: 0.5rem 1rem 0.5rem 0;
    cursor: pointer;
    flex-shrink: 0;
    height: 16px;
    width: 16px;

    @include md {
      margin: 0.5rem 1rem;
    }
  }
}
