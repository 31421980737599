/********* Colors *********/
$primary-color: #b8292f;
$primary-color-dark: #78191d;
$secondary-color: #64b544;
$secondary-color-dark: #4a8f2f;

$background-color: #f5f3ec;
$box-shadow-color: rgba(0, 0, 0, 0.1);
$border-color: #d4d1c2;
$error-color: #b8292f;

$input-color: white;
$input-text-color: $primary-color;
$input-placeholder-text-color: #303030;
$textarea-text-color: #303030;

$label-background-color: #af272f;
$label-text-color: white;
$choice-info-background-color: #edeade;
$choice-info-text-color: #817e77;

$text-color: #303030;
$page-title-color: #303030;
$page-title-border-color: #b3b1a8;

$grid-background-color-even: #edeade;
$grid-text-color-even: #7c2529;
$grid-border-color-even: #7c2529;
$grid-background-color-odd: #7c2529;
$grid-text-color-odd: white;
$grid-promote-background-color: $primary-color;
$grid-promote-text-color: white;

$product-background-color: white;
$product-title-color: $primary-color;
$product-subtitle-color: $primary-color-dark;
$product-text-color: $primary-color;
$product-border-color: #b3b1a8;
$product-active-button-background-color: $primary-color-dark;
$product-active-button-border-color: $primary-color-dark;
$product-modifier-background-color: #edeade;
$product-modifier-border-color: #d4d1c2;
$product-no-modifier-price-text-color: $primary-color-dark;
$product-counter-number-text-color: #d4d1c2;
$product-active-counter-number-text-color: $primary-color;
$product-sale-price-text-color: $primary-color-dark;

$modifier-background-color: white;
$modifier-border-color: #b3b1a8;
$modifier-title-text-color: $primary-color;
$modifier-counter-text-color: #d4d1c2;
$modifier-extra-choice-text-color: $primary-color;

$choice-background-color: white;
$choice-active-text-color: $primary-color;

$precheckout-products-background-color: #edeade;
$precheckout-products-text-color: #484848;
$precheckout-products-active-text-color: $primary-color;

$checkout-title-text-color: $primary-color;
$checkout-title-count-text-color: #d4d1c2;
$checkout-border-color: #d4d1c2;
$checkout-total-background-color: #edeade;
$checkout-pickup-title-text-color: $primary-color;
$checkout-inline-pickup-background-color: $primary-color;
$checkout-inline-pickup-text-color: white;

$delivery-option-background-color: white;
$delivery-fee-background-color: #9acd32;

$order-title-text-color: $primary-color;

$button-color: #ececec;
$button-text-color: #303030;
$accent-button-color: #ececec;
$accent-button-text-color: #303030;
$muted-button-color: #e5e3da;
$muted-button-text-color: #e5e3da;

$header-background-color: #edeade;

$minicart-bubble-color: $primary-color-dark;
$minicart-bubble-text-color: white;

$expanded-cart-background-color: #f5f3ed;
$expanded-cart-border-color: #d4d1c2;

$summarycart-background-color: $primary-color;
$summarycart-text-color: white;
$summarycart-bubble-color: $primary-color-dark;
$summarycart-bubble-text-color: white;

$sidebar-background-color: $primary-color;
$sidebar-active-background-color: $primary-color-dark;
$sidebar-text-color: white;
$sidebar-border-color: #dddddd;
$sidebar-accent-background-color: #cd2e34;
$sidebar-accent-text-color: white;

$counter-background-icon: #f5f3ec;
$counter-border-color: #edeade;
$counter-hover-background-icon: $primary-color;
$counter-icon-color: white;
$counter-text-color: #303030;

$basket-total-background-color: #edeade;
$basket-total-background-color-dark: #ccc8b4;
$simple-total-border-color: #d4d1c2;

$spinner-background: rgba(48, 60, 55, 0.15);
$spinner-color: rgba(48, 60, 55, 0.8);

$datepicker-background-color: #edeade;
$datepicker-text-color: #303030;
$datepicker-disabled-text-color: #d4d1c2;
$datepicker-selected-background-color: $primary-color;
$datepicker-selected-text-color: white;

$timepicker-background-color: #edeade;
$timepicker-even-row-background-color: #e6e2d1;
$timepicker-text-color: #303030;

/********* Fonts *********/
$primary-font: Arvo, serif;
$secondary-font: Roboto, sans-serif;
$accent-font-italic: Lobster, cursive;
$accent-font: "Lobster Two", cursive;

/********* Event *********/
$event-beige: #dab584;
$event-blue: #161926;
$event-primary-font: Overpass;
$event-secondary-font: "Playfair Display";

$topbar-height: 63px;
$topbar-height-sm: 100px;
