@use "assets/styles/global" as *;

.category-grid {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.gridbox {
  animation-duration: 500ms;
  animation-name: fadein;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include sm {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  &:nth-child(even) {
    .gridbox-image,
    .gridbox-info {
      background-color: $grid-background-color-even;
      color: $grid-text-color-even;
    }

    @include sm {
      .gridbox-image {
        order: 1;
      }

      .gridbox-info {
        order: 2;

        .gridbox-description {
          border-color: $grid-border-color-even;
        }
      }
    }
  }

  &:nth-child(odd) {
    .gridbox-image,
    .gridbox-info {
      background-color: $grid-background-color-odd;
      color: $grid-text-color-odd;
    }

    @include sm {
      .gridbox-image {
        order: 2;
      }

      .gridbox-info {
        order: 1;
      }
    }
  }

  &:first-child {
    .gridbox-image,
    .gridbox-info {
      background-color: $grid-promote-background-color;
      color: $grid-promote-text-color;
    }
  }

  .gridbox-image {
    height: 260px;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include sm {
      height: 100%;
      min-height: 430px;
    }
  }

  .gridbox-info {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin: 0;
    min-height: 4rem;

    @include sm {
      align-items: flex-start;
      justify-content: flex-start;
      padding: 50px;
    }

    @include md {
      padding: 50px 100px;
    }
  }

  .gridbox-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    overflow-wrap: break-word;
    padding: 1rem;

    @include sm {
      box-sizing: border-box;
      font-size: 36px;
      overflow-wrap: normal;
      padding: 0;
      padding-bottom: 20px;
      text-align: left;
      width: 100%;
    }
  }

  .gridbox-description {
    font-size: 14px;
    margin: 0;
    padding: 1rem;
    padding-top: 0;
    text-align: center;
    white-space: pre-line;

    @include sm {
      border-top: 1px dashed white;
      font-size: 24px;
      padding: 0;
      padding-top: 30px;
      text-align: left;
      width: 100%;
    }
  }

  .gridbox-spacing {
    border-bottom: 1px dashed $page-title-border-color;
    margin: 30px auto;
    width: 70px;

    @include sm {
      grid-column: span 2 / span 2;
      order: 3;
      width: 80%;
    }
  }
}
