@use "assets/styles/global" as *;

.counter {
  align-items: center;
  display: grid;
  font-size: 12px;
  grid-template-columns: 1fr 0.75fr 1fr;
  justify-items: center;
  text-align: center;

  .counter-text {
    margin: 0;
    min-width: 4px;
    font-weight: bold;
    text-align: center;
  }

  .counter-text-number {
    color: $counter-text-color;
    font-size: 18px;
  }

  .count-btn {
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 2px 6px #00000029;
    padding: 0;

    font-size: 18px;

    width: 24px;
    height: 24px;

    text-indent: -999em;
    color: $counter-icon-color;

    background-color: $counter-background-icon;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 14px;

    cursor: pointer;

    &:not(:disabled):hover {
      background-color: $counter-hover-background-icon;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: default;
      opacity: 0.7;
    }
  }

  .minus {
    margin-right: 20px;
  }

  .plus {
    margin-left: 20px;
  }
}
