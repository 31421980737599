@use "assets/styles/global" as *;

.restaurant-pickup-info {
  margin: 2rem 0;

  h1 {
    color: $checkout-pickup-title-text-color;
    font-size: 20px;
    margin-bottom: 1.25rem;
    text-transform: uppercase;
  }
}

.event .restaurant-pickup-info h1 {
  font-family: $event-secondary-font;
  text-transform: none;
}

.location {
  h1 {
    color: $text-color;
    font-size: 18px;
    margin-bottom: 0;
  }

  p {
    margin: 0;
    margin-top: 0.3rem;
  }
}

.event .restaurant-pickup-info h1 {
  color: $event-blue;
}
